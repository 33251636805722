// font family
@font-face {
	font-family: "Helvetica";
	src: url("../fonts/Helvetica.woff");
	src: url("../fonts/Helvetica.woff");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Inter-Medium";
	src: url("../fonts/Inter-Medium.woff");
	src: url("../fonts/Inter-Medium.woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Inter-SemiBold";
	src: url("../fonts/Inter-SemiBold.woff");
	src: url("../fonts/Inter-SemiBold.woff2");
	font-weight: 600;
	font-style: normal;
}
