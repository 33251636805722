@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variable.scss';
@import './mixins.scss';
@import './fonts.scss';

// basic overwriting styles

h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul,
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: var(--font-m);
  color: var(--body-bg);
  @include Font(0.875rem, 1.375rem);
}
* {
  box-sizing: border-box;
}
li {
  list-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: var(--font-m);
  @include Font(0.875rem, 1.375rem);
}

// input number icon removing
input[type='number'] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

// form control styles
.form-control {
  margin-bottom: 20px;
  label {
    display: block;
    font-family: var(--font-r);
  }
  input {
    &::placeholder {
      color: var(--base-7);
    }
  }
}

// fonts classes
.font-interR {
  font-family: var(--font-r);
}
.font-interSb {
  font-family: var(--font-sb);
}

details > summary {
  list-style: none;
  display: flex;
  justify-content: center;
}
details > summary::-webkit-details-marker {
  display: none;
}
details[open] {
  summary img {
    transform: rotate(180deg);
  }
}
.cta-bg {
  background-image: url('../../public/images/common/cta-banner.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

// header mobile styles...
@media screen and (max-width: 768px) {
  .mobileMenu {
    display: none;
    background-color: var(--white);
    position: absolute;
    top: 80px;
    width: 100%;
    height: 100lvh;
    left: 0;
    right: 0;
    padding: 26px 16px 16px;
    z-index: 1;
    .mobBtn {
      position: absolute;
      bottom: 75px;
      left: 16px;
      right: 16px;
    }
    li {
      margin-bottom: 28px;
      display: block;
    }
    &.Active {
      display: block;
    }
  }
  .cta-bg {
    background-size: cover;
  }
}
