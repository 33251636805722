:root {
	// colors
	--white: #ffffff;
	--black: #000000;
	--base-1: #fafbfc;
	--body-bg: #555555;
	--line-1: #ececec;
	--base-2: #008785;
	--base-3: #eeeeee80;
	--base-4: #dcdcdc;
	--base-5: #028c78;
	--red: #eb5757;
	--base-6: #5555551a;
	--base-7: #d9d9d9;

	// fonts
	--font-r: "Helvetica";
	--font-m: "Inter-Medium";
	--font-sb: "Inter-SemiBold";
}
